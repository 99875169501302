import { GraphQLResolveInfo } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ActiveGamesListResponse = {
  __typename?: 'ActiveGamesListResponse';
  games: Array<GameWithTags>;
};

export type BalanceResponse = {
  __typename?: 'BalanceResponse';
  candy: Scalars['String']['output'];
  cashout: Scalars['String']['output'];
  entry: Scalars['String']['output'];
  winnings: Scalars['String']['output'];
};

export type CreateGameSessionResponse = {
  __typename?: 'CreateGameSessionResponse';
  gameSessionId: Scalars['String']['output'];
  gameUrl: Scalars['String']['output'];
};

export type EndGameResponse = {
  __typename?: 'EndGameResponse';
  success: Scalars['Boolean']['output'];
};

export type GameTag = {
  __typename?: 'GameTag';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type GameTagsResponse = {
  __typename?: 'GameTagsResponse';
  gameTags: Array<GameTag>;
};

export type GameWithTags = {
  __typename?: 'GameWithTags';
  displayName?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  gameCategory: Scalars['String']['output'];
  gameTags: Array<GameTag>;
  id: Scalars['Int']['output'];
  imgUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type InitializeStationResponse = {
  __typename?: 'InitializeStationResponse';
  certificate: Scalars['String']['output'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  playerId: Scalars['String']['output'];
  playerSessionId: Scalars['String']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  success: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  applyCandy: Array<UserPet>;
  claimPet: UserPet;
  endGameSession: EndGameResponse;
  forgetPassword?: Maybe<ForgetPasswordResponse>;
  initializeStation: InitializeStationResponse;
  logout: LogoutResponse;
  playFromHomeLogin: LoginResponse;
  redeemWinnings: RedeemWinningsResponse;
  refreshStationCertificate: Scalars['String']['output'];
  refreshToken: RefreshTokenResponse;
  setPassword: SetPassResponse;
  startGameSession: CreateGameSessionResponse;
  stationLogin: LoginResponse;
};


export type MutationApplyCandyArgs = {
  candy: Scalars['Int']['input'];
  petId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};


export type MutationClaimPetArgs = {
  petId: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};


export type MutationEndGameSessionArgs = {
  gameSessionId: Scalars['String']['input'];
};


export type MutationForgetPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationInitializeStationArgs = {
  certificate: Scalars['String']['input'];
  secret: Scalars['String']['input'];
  stationAppVersion: Scalars['String']['input'];
};


export type MutationLogoutArgs = {
  sessionKey: Scalars['String']['input'];
};


export type MutationPlayFromHomeLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRedeemWinningsArgs = {
  sessionKey: Scalars['String']['input'];
  totalWinnings: Scalars['Float']['input'];
  winPercent: Scalars['Float']['input'];
};


export type MutationRefreshStationCertificateArgs = {
  newCert: Scalars['String']['input'];
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationSetPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationStartGameSessionArgs = {
  gameId: Scalars['Int']['input'];
  playerSessionId: Scalars['String']['input'];
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};


export type MutationStationLoginArgs = {
  cardSerialNumber: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  canClaimPet: Scalars['Int']['output'];
  checkNetworkStatus: Scalars['Boolean']['output'];
  getActiveGamesList: ActiveGamesListResponse;
  getBalance: BalanceResponse;
  getGameTags: GameTagsResponse;
  getStationData: StationDataResponse;
  getStationName: Scalars['String']['output'];
  getUserPets: Array<UserPet>;
  isFirstTimePetOwner: Scalars['Boolean']['output'];
};


export type QueryCanClaimPetArgs = {
  playerId: Scalars['String']['input'];
};


export type QueryGetActiveGamesListArgs = {
  gameTag?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetBalanceArgs = {
  playerId: Scalars['String']['input'];
};


export type QueryGetStationDataArgs = {
  secret: Scalars['String']['input'];
};


export type QueryGetStationNameArgs = {
  stationId: Scalars['String']['input'];
};


export type QueryGetUserPetsArgs = {
  playerId: Scalars['String']['input'];
};


export type QueryIsFirstTimePetOwnerArgs = {
  playerId: Scalars['String']['input'];
};

export type RedeemWinningsResponse = {
  __typename?: 'RedeemWinningsResponse';
  entry: Scalars['String']['output'];
  totalWinnings: Scalars['Float']['output'];
  totalWinningsGame: Scalars['Float']['output'];
  winnings: Scalars['String']['output'];
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

export type StationDataResponse = {
  __typename?: 'StationDataResponse';
  id: Scalars['String']['output'];
  roomId: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  balance: BalanceResponse;
};


export type SubscriptionBalanceArgs = {
  playerId: Scalars['String']['input'];
};

export type UserPet = {
  __typename?: 'UserPet';
  candyToNextLevel: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  petId: Scalars['String']['output'];
};

export type ForgetPasswordResponse = {
  __typename?: 'forgetPasswordResponse';
  success: Scalars['Boolean']['output'];
};

export type SetPassResponse = {
  __typename?: 'setPassResponse';
  success: Scalars['Boolean']['output'];
};

export type BalanceSubscriptionVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type BalanceSubscription = { __typename?: 'Subscription', balance: { __typename?: 'BalanceResponse', entry: string, winnings: string, candy: string, cashout: string } };

export type CheckNetworkStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckNetworkStatusQuery = { __typename?: 'Query', checkNetworkStatus: boolean };

export type GetActiveGamesListQueryVariables = Exact<{
  gameTag?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetActiveGamesListQuery = { __typename?: 'Query', getActiveGamesList: { __typename?: 'ActiveGamesListResponse', games: Array<{ __typename?: 'GameWithTags', id: number, name: string, externalId?: string | null, source?: string | null, displayName?: string | null, gameCategory: string, isActive: boolean, imgUrl?: string | null, videoUrl?: string | null, gameTags: Array<{ __typename?: 'GameTag', id: number, name: string }> }> } };

export type GetGameTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGameTagsQuery = { __typename?: 'Query', getGameTags: { __typename?: 'GameTagsResponse', gameTags: Array<{ __typename?: 'GameTag', id: number, name: string }> } };

export type GetStationDataQueryVariables = Exact<{
  secret: Scalars['String']['input'];
}>;


export type GetStationDataQuery = { __typename?: 'Query', getStationData: { __typename?: 'StationDataResponse', id: string, roomId: string } };

export type GetBalanceQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type GetBalanceQuery = { __typename?: 'Query', getBalance: { __typename?: 'BalanceResponse', entry: string, winnings: string, candy: string, cashout: string } };

export type GetUserPetsQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type GetUserPetsQuery = { __typename?: 'Query', getUserPets: Array<{ __typename?: 'UserPet', id: string, level: number, candyToNextLevel: number, petId: string }> };

export type CanClaimPetQueryVariables = Exact<{
  playerId: Scalars['String']['input'];
}>;


export type CanClaimPetQuery = { __typename?: 'Query', canClaimPet: number };

export type GetStationNameQueryVariables = Exact<{
  stationId: Scalars['String']['input'];
}>;


export type GetStationNameQuery = { __typename?: 'Query', getStationName: string };

export type PlayFromHomeLoginMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type PlayFromHomeLoginMutation = { __typename?: 'Mutation', playFromHomeLogin: { __typename?: 'LoginResponse', token: string, refreshToken: string, playerSessionId: string, playerId: string, username?: string | null } };

export type StationLoginMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
  cardSerialNumber: Scalars['String']['input'];
}>;


export type StationLoginMutation = { __typename?: 'Mutation', stationLogin: { __typename?: 'LoginResponse', token: string, refreshToken: string, playerSessionId: string, playerId: string, username?: string | null } };

export type LogoutMutationVariables = Exact<{
  sessionKey: Scalars['String']['input'];
}>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutResponse', success: boolean } };

export type InitializeStationMutationVariables = Exact<{
  secret: Scalars['String']['input'];
  certificate: Scalars['String']['input'];
  stationAppVersion: Scalars['String']['input'];
}>;


export type InitializeStationMutation = { __typename?: 'Mutation', initializeStation: { __typename?: 'InitializeStationResponse', certificate: string } };

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String']['input'];
}>;


export type RefreshTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'RefreshTokenResponse', token: string, refreshToken: string } };

export type RefreshStationCertificateMutationVariables = Exact<{
  newCert: Scalars['String']['input'];
}>;


export type RefreshStationCertificateMutation = { __typename?: 'Mutation', refreshStationCertificate: string };

export type StartGameSessionMutationVariables = Exact<{
  gameId: Scalars['Int']['input'];
  playerSessionId: Scalars['String']['input'];
  returnUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type StartGameSessionMutation = { __typename?: 'Mutation', startGameSession: { __typename?: 'CreateGameSessionResponse', gameUrl: string, gameSessionId: string } };

export type RedeemWinningsMutationVariables = Exact<{
  sessionKey: Scalars['String']['input'];
  winPercent: Scalars['Float']['input'];
  totalWinnings: Scalars['Float']['input'];
}>;


export type RedeemWinningsMutation = { __typename?: 'Mutation', redeemWinnings: { __typename?: 'RedeemWinningsResponse', entry: string, winnings: string, totalWinningsGame: number, totalWinnings: number } };

export type EndGameSessionMutationVariables = Exact<{
  gameSessionId: Scalars['String']['input'];
}>;


export type EndGameSessionMutation = { __typename?: 'Mutation', endGameSession: { __typename?: 'EndGameResponse', success: boolean } };

export type ForgetPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgetPasswordMutation = { __typename?: 'Mutation', forgetPassword?: { __typename?: 'forgetPasswordResponse', success: boolean } | null };

export type SetPasswordMutationVariables = Exact<{
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SetPasswordMutation = { __typename?: 'Mutation', setPassword: { __typename?: 'setPassResponse', success: boolean } };

export type ClaimPetMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
  petId: Scalars['String']['input'];
}>;


export type ClaimPetMutation = { __typename?: 'Mutation', claimPet: { __typename?: 'UserPet', id: string, level: number, candyToNextLevel: number, petId: string } };

export type ApplyCandyMutationVariables = Exact<{
  playerId: Scalars['String']['input'];
  petId: Scalars['String']['input'];
  candy: Scalars['Int']['input'];
}>;


export type ApplyCandyMutation = { __typename?: 'Mutation', applyCandy: Array<{ __typename?: 'UserPet', id: string, level: number, candyToNextLevel: number, petId: string }> };


export const BalanceDocument = gql`
    subscription balance($playerId: String!) {
  balance(playerId: $playerId) {
    entry
    winnings
    candy
    cashout
  }
}
    `;

export function useBalanceSubscription<TData = BalanceSubscription>(options: Omit<Urql.UseSubscriptionArgs<BalanceSubscriptionVariables>, 'query'>, handler?: Urql.SubscriptionHandler<BalanceSubscription, TData>) {
  return Urql.useSubscription<BalanceSubscription, TData, BalanceSubscriptionVariables>({ query: BalanceDocument, ...options }, handler);
};
export const CheckNetworkStatusDocument = gql`
    query checkNetworkStatus {
  checkNetworkStatus
}
    `;

export function useCheckNetworkStatusQuery(options?: Omit<Urql.UseQueryArgs<CheckNetworkStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckNetworkStatusQuery, CheckNetworkStatusQueryVariables>({ query: CheckNetworkStatusDocument, ...options });
};
export const GetActiveGamesListDocument = gql`
    query getActiveGamesList($gameTag: Int) {
  getActiveGamesList(gameTag: $gameTag) {
    games {
      id
      name
      externalId
      source
      displayName
      gameCategory
      isActive
      imgUrl
      videoUrl
      gameTags {
        id
        name
      }
    }
  }
}
    `;

export function useGetActiveGamesListQuery(options?: Omit<Urql.UseQueryArgs<GetActiveGamesListQueryVariables>, 'query'>) {
  return Urql.useQuery<GetActiveGamesListQuery, GetActiveGamesListQueryVariables>({ query: GetActiveGamesListDocument, ...options });
};
export const GetGameTagsDocument = gql`
    query getGameTags {
  getGameTags {
    gameTags {
      id
      name
    }
  }
}
    `;

export function useGetGameTagsQuery(options?: Omit<Urql.UseQueryArgs<GetGameTagsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGameTagsQuery, GetGameTagsQueryVariables>({ query: GetGameTagsDocument, ...options });
};
export const GetStationDataDocument = gql`
    query getStationData($secret: String!) {
  getStationData(secret: $secret) {
    id
    roomId
  }
}
    `;

export function useGetStationDataQuery(options: Omit<Urql.UseQueryArgs<GetStationDataQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationDataQuery, GetStationDataQueryVariables>({ query: GetStationDataDocument, ...options });
};
export const GetBalanceDocument = gql`
    query getBalance($playerId: String!) {
  getBalance(playerId: $playerId) {
    entry
    winnings
    candy
    cashout
  }
}
    `;

export function useGetBalanceQuery(options: Omit<Urql.UseQueryArgs<GetBalanceQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBalanceQuery, GetBalanceQueryVariables>({ query: GetBalanceDocument, ...options });
};
export const GetUserPetsDocument = gql`
    query getUserPets($playerId: String!) {
  getUserPets(playerId: $playerId) {
    id
    level
    candyToNextLevel
    petId
  }
}
    `;

export function useGetUserPetsQuery(options: Omit<Urql.UseQueryArgs<GetUserPetsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserPetsQuery, GetUserPetsQueryVariables>({ query: GetUserPetsDocument, ...options });
};
export const CanClaimPetDocument = gql`
    query canClaimPet($playerId: String!) {
  canClaimPet(playerId: $playerId)
}
    `;

export function useCanClaimPetQuery(options: Omit<Urql.UseQueryArgs<CanClaimPetQueryVariables>, 'query'>) {
  return Urql.useQuery<CanClaimPetQuery, CanClaimPetQueryVariables>({ query: CanClaimPetDocument, ...options });
};
export const GetStationNameDocument = gql`
    query getStationName($stationId: String!) {
  getStationName(stationId: $stationId)
}
    `;

export function useGetStationNameQuery(options: Omit<Urql.UseQueryArgs<GetStationNameQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStationNameQuery, GetStationNameQueryVariables>({ query: GetStationNameDocument, ...options });
};
export const PlayFromHomeLoginDocument = gql`
    mutation playFromHomeLogin($email: String!, $password: String!) {
  playFromHomeLogin(email: $email, password: $password) {
    token
    refreshToken
    playerSessionId
    playerId
    username
  }
}
    `;

export function usePlayFromHomeLoginMutation() {
  return Urql.useMutation<PlayFromHomeLoginMutation, PlayFromHomeLoginMutationVariables>(PlayFromHomeLoginDocument);
};
export const StationLoginDocument = gql`
    mutation stationLogin($playerId: String!, $cardSerialNumber: String!) {
  stationLogin(playerId: $playerId, cardSerialNumber: $cardSerialNumber) {
    token
    refreshToken
    playerSessionId
    playerId
    username
  }
}
    `;

export function useStationLoginMutation() {
  return Urql.useMutation<StationLoginMutation, StationLoginMutationVariables>(StationLoginDocument);
};
export const LogoutDocument = gql`
    mutation logout($sessionKey: String!) {
  logout(sessionKey: $sessionKey) {
    success
  }
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const InitializeStationDocument = gql`
    mutation initializeStation($secret: String!, $certificate: String!, $stationAppVersion: String!) {
  initializeStation(
    secret: $secret
    certificate: $certificate
    stationAppVersion: $stationAppVersion
  ) {
    certificate
  }
}
    `;

export function useInitializeStationMutation() {
  return Urql.useMutation<InitializeStationMutation, InitializeStationMutationVariables>(InitializeStationDocument);
};
export const RefreshTokenDocument = gql`
    mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
  }
}
    `;

export function useRefreshTokenMutation() {
  return Urql.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument);
};
export const RefreshStationCertificateDocument = gql`
    mutation refreshStationCertificate($newCert: String!) {
  refreshStationCertificate(newCert: $newCert)
}
    `;

export function useRefreshStationCertificateMutation() {
  return Urql.useMutation<RefreshStationCertificateMutation, RefreshStationCertificateMutationVariables>(RefreshStationCertificateDocument);
};
export const StartGameSessionDocument = gql`
    mutation startGameSession($gameId: Int!, $playerSessionId: String!, $returnUrl: String) {
  startGameSession(
    gameId: $gameId
    playerSessionId: $playerSessionId
    returnUrl: $returnUrl
  ) {
    gameUrl
    gameSessionId
  }
}
    `;

export function useStartGameSessionMutation() {
  return Urql.useMutation<StartGameSessionMutation, StartGameSessionMutationVariables>(StartGameSessionDocument);
};
export const RedeemWinningsDocument = gql`
    mutation redeemWinnings($sessionKey: String!, $winPercent: Float!, $totalWinnings: Float!) {
  redeemWinnings(
    sessionKey: $sessionKey
    winPercent: $winPercent
    totalWinnings: $totalWinnings
  ) {
    entry
    winnings
    totalWinningsGame
    totalWinnings
  }
}
    `;

export function useRedeemWinningsMutation() {
  return Urql.useMutation<RedeemWinningsMutation, RedeemWinningsMutationVariables>(RedeemWinningsDocument);
};
export const EndGameSessionDocument = gql`
    mutation endGameSession($gameSessionId: String!) {
  endGameSession(gameSessionId: $gameSessionId) {
    success
  }
}
    `;

export function useEndGameSessionMutation() {
  return Urql.useMutation<EndGameSessionMutation, EndGameSessionMutationVariables>(EndGameSessionDocument);
};
export const ForgetPasswordDocument = gql`
    mutation forgetPassword($email: String!) {
  forgetPassword(email: $email) {
    success
  }
}
    `;

export function useForgetPasswordMutation() {
  return Urql.useMutation<ForgetPasswordMutation, ForgetPasswordMutationVariables>(ForgetPasswordDocument);
};
export const SetPasswordDocument = gql`
    mutation setPassword($code: String!, $password: String!) {
  setPassword(code: $code, password: $password) {
    success
  }
}
    `;

export function useSetPasswordMutation() {
  return Urql.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument);
};
export const ClaimPetDocument = gql`
    mutation claimPet($playerId: String!, $petId: String!) {
  claimPet(playerId: $playerId, petId: $petId) {
    id
    level
    candyToNextLevel
    petId
  }
}
    `;

export function useClaimPetMutation() {
  return Urql.useMutation<ClaimPetMutation, ClaimPetMutationVariables>(ClaimPetDocument);
};
export const ApplyCandyDocument = gql`
    mutation applyCandy($playerId: String!, $petId: String!, $candy: Int!) {
  applyCandy(playerId: $playerId, petId: $petId, candy: $candy) {
    id
    level
    candyToNextLevel
    petId
  }
}
    `;

export function useApplyCandyMutation() {
  return Urql.useMutation<ApplyCandyMutation, ApplyCandyMutationVariables>(ApplyCandyDocument);
};


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  ActiveGamesListResponse: ResolverTypeWrapper<ActiveGamesListResponse>;
  BalanceResponse: ResolverTypeWrapper<BalanceResponse>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CreateGameSessionResponse: ResolverTypeWrapper<CreateGameSessionResponse>;
  EndGameResponse: ResolverTypeWrapper<EndGameResponse>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GameTag: ResolverTypeWrapper<GameTag>;
  GameTagsResponse: ResolverTypeWrapper<GameTagsResponse>;
  GameWithTags: ResolverTypeWrapper<GameWithTags>;
  InitializeStationResponse: ResolverTypeWrapper<InitializeStationResponse>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  LoginResponse: ResolverTypeWrapper<LoginResponse>;
  LogoutResponse: ResolverTypeWrapper<LogoutResponse>;
  Mutation: ResolverTypeWrapper<{}>;
  Query: ResolverTypeWrapper<{}>;
  RedeemWinningsResponse: ResolverTypeWrapper<RedeemWinningsResponse>;
  RefreshTokenResponse: ResolverTypeWrapper<RefreshTokenResponse>;
  StationDataResponse: ResolverTypeWrapper<StationDataResponse>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Subscription: ResolverTypeWrapper<{}>;
  UserPet: ResolverTypeWrapper<UserPet>;
  forgetPasswordResponse: ResolverTypeWrapper<ForgetPasswordResponse>;
  setPassResponse: ResolverTypeWrapper<SetPassResponse>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActiveGamesListResponse: ActiveGamesListResponse;
  BalanceResponse: BalanceResponse;
  Boolean: Scalars['Boolean']['output'];
  CreateGameSessionResponse: CreateGameSessionResponse;
  EndGameResponse: EndGameResponse;
  Float: Scalars['Float']['output'];
  GameTag: GameTag;
  GameTagsResponse: GameTagsResponse;
  GameWithTags: GameWithTags;
  InitializeStationResponse: InitializeStationResponse;
  Int: Scalars['Int']['output'];
  LoginResponse: LoginResponse;
  LogoutResponse: LogoutResponse;
  Mutation: {};
  Query: {};
  RedeemWinningsResponse: RedeemWinningsResponse;
  RefreshTokenResponse: RefreshTokenResponse;
  StationDataResponse: StationDataResponse;
  String: Scalars['String']['output'];
  Subscription: {};
  UserPet: UserPet;
  forgetPasswordResponse: ForgetPasswordResponse;
  setPassResponse: SetPassResponse;
};

export type ActiveGamesListResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActiveGamesListResponse'] = ResolversParentTypes['ActiveGamesListResponse']> = {
  games?: Resolver<Array<ResolversTypes['GameWithTags']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BalanceResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['BalanceResponse'] = ResolversParentTypes['BalanceResponse']> = {
  candy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  cashout?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  winnings?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateGameSessionResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateGameSessionResponse'] = ResolversParentTypes['CreateGameSessionResponse']> = {
  gameSessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EndGameResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['EndGameResponse'] = ResolversParentTypes['EndGameResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameTagResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameTag'] = ResolversParentTypes['GameTag']> = {
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameTagsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameTagsResponse'] = ResolversParentTypes['GameTagsResponse']> = {
  gameTags?: Resolver<Array<ResolversTypes['GameTag']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameWithTagsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GameWithTags'] = ResolversParentTypes['GameWithTags']> = {
  displayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  externalId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gameCategory?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameTags?: Resolver<Array<ResolversTypes['GameTag']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imgUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  source?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  videoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InitializeStationResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InitializeStationResponse'] = ResolversParentTypes['InitializeStationResponse']> = {
  certificate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse']> = {
  playerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerSessionId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogoutResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogoutResponse'] = ResolversParentTypes['LogoutResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  applyCandy?: Resolver<Array<ResolversTypes['UserPet']>, ParentType, ContextType, RequireFields<MutationApplyCandyArgs, 'candy' | 'petId' | 'playerId'>>;
  claimPet?: Resolver<ResolversTypes['UserPet'], ParentType, ContextType, RequireFields<MutationClaimPetArgs, 'petId' | 'playerId'>>;
  endGameSession?: Resolver<ResolversTypes['EndGameResponse'], ParentType, ContextType, RequireFields<MutationEndGameSessionArgs, 'gameSessionId'>>;
  forgetPassword?: Resolver<Maybe<ResolversTypes['forgetPasswordResponse']>, ParentType, ContextType, RequireFields<MutationForgetPasswordArgs, 'email'>>;
  initializeStation?: Resolver<ResolversTypes['InitializeStationResponse'], ParentType, ContextType, RequireFields<MutationInitializeStationArgs, 'certificate' | 'secret' | 'stationAppVersion'>>;
  logout?: Resolver<ResolversTypes['LogoutResponse'], ParentType, ContextType, RequireFields<MutationLogoutArgs, 'sessionKey'>>;
  playFromHomeLogin?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<MutationPlayFromHomeLoginArgs, 'email' | 'password'>>;
  redeemWinnings?: Resolver<ResolversTypes['RedeemWinningsResponse'], ParentType, ContextType, RequireFields<MutationRedeemWinningsArgs, 'sessionKey' | 'totalWinnings' | 'winPercent'>>;
  refreshStationCertificate?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRefreshStationCertificateArgs, 'newCert'>>;
  refreshToken?: Resolver<ResolversTypes['RefreshTokenResponse'], ParentType, ContextType, RequireFields<MutationRefreshTokenArgs, 'refreshToken'>>;
  setPassword?: Resolver<ResolversTypes['setPassResponse'], ParentType, ContextType, RequireFields<MutationSetPasswordArgs, 'code' | 'password'>>;
  startGameSession?: Resolver<ResolversTypes['CreateGameSessionResponse'], ParentType, ContextType, RequireFields<MutationStartGameSessionArgs, 'gameId' | 'playerSessionId'>>;
  stationLogin?: Resolver<ResolversTypes['LoginResponse'], ParentType, ContextType, RequireFields<MutationStationLoginArgs, 'cardSerialNumber' | 'playerId'>>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  canClaimPet?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<QueryCanClaimPetArgs, 'playerId'>>;
  checkNetworkStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  getActiveGamesList?: Resolver<ResolversTypes['ActiveGamesListResponse'], ParentType, ContextType, Partial<QueryGetActiveGamesListArgs>>;
  getBalance?: Resolver<ResolversTypes['BalanceResponse'], ParentType, ContextType, RequireFields<QueryGetBalanceArgs, 'playerId'>>;
  getGameTags?: Resolver<ResolversTypes['GameTagsResponse'], ParentType, ContextType>;
  getStationData?: Resolver<ResolversTypes['StationDataResponse'], ParentType, ContextType, RequireFields<QueryGetStationDataArgs, 'secret'>>;
  getStationName?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetStationNameArgs, 'stationId'>>;
  getUserPets?: Resolver<Array<ResolversTypes['UserPet']>, ParentType, ContextType, RequireFields<QueryGetUserPetsArgs, 'playerId'>>;
  isFirstTimePetOwner?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryIsFirstTimePetOwnerArgs, 'playerId'>>;
};

export type RedeemWinningsResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RedeemWinningsResponse'] = ResolversParentTypes['RedeemWinningsResponse']> = {
  entry?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalWinnings?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalWinningsGame?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  winnings?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RefreshTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefreshTokenResponse'] = ResolversParentTypes['RefreshTokenResponse']> = {
  refreshToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StationDataResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['StationDataResponse'] = ResolversParentTypes['StationDataResponse']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  balance?: SubscriptionResolver<ResolversTypes['BalanceResponse'], "balance", ParentType, ContextType, RequireFields<SubscriptionBalanceArgs, 'playerId'>>;
};

export type UserPetResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPet'] = ResolversParentTypes['UserPet']> = {
  candyToNextLevel?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  petId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ForgetPasswordResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['forgetPasswordResponse'] = ResolversParentTypes['forgetPasswordResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SetPassResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['setPassResponse'] = ResolversParentTypes['setPassResponse']> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ActiveGamesListResponse?: ActiveGamesListResponseResolvers<ContextType>;
  BalanceResponse?: BalanceResponseResolvers<ContextType>;
  CreateGameSessionResponse?: CreateGameSessionResponseResolvers<ContextType>;
  EndGameResponse?: EndGameResponseResolvers<ContextType>;
  GameTag?: GameTagResolvers<ContextType>;
  GameTagsResponse?: GameTagsResponseResolvers<ContextType>;
  GameWithTags?: GameWithTagsResolvers<ContextType>;
  InitializeStationResponse?: InitializeStationResponseResolvers<ContextType>;
  LoginResponse?: LoginResponseResolvers<ContextType>;
  LogoutResponse?: LogoutResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RedeemWinningsResponse?: RedeemWinningsResponseResolvers<ContextType>;
  RefreshTokenResponse?: RefreshTokenResponseResolvers<ContextType>;
  StationDataResponse?: StationDataResponseResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  UserPet?: UserPetResolvers<ContextType>;
  forgetPasswordResponse?: ForgetPasswordResponseResolvers<ContextType>;
  setPassResponse?: SetPassResponseResolvers<ContextType>;
};

