import { useMemo } from "react";
import toast from "react-hot-toast";
import {
  createClient,
  Provider as GQLProvider,
  cacheExchange,
  fetchExchange,
  mapExchange,
  errorExchange,
  subscriptionExchange,
} from "urql";
import { createClient as createWSClient, SubscribePayload } from "graphql-ws";
import { authExchange } from "@urql/exchange-auth";
import { captureException } from "@sentry/react";
import { Auth } from "./Auth";
import { useStationAuth } from "./contexts/StationAuthContext";

const auth = new Auth();

export const GqlProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { jwt, cert, signature, setMaintenanceMode, hardReset } =
    useStationAuth();

  const client = useMemo(() => {
    const wsClient = createWSClient({
      url: import.meta.env.VITE_APP_WS!,
      connectionParams: () => ({
        Authorization: jwt ? `Bearer ${jwt}` : "",
        "X-Certificate": cert || "",
        "X-Signature": signature || "",
      }),
      shouldRetry: () => true,
      retryWait: async function waitForServerHealthyBeforeRetry() {
        // if you have a server healthcheck, you can wait for it to become
        // healthy before retrying after an abrupt disconnect (most commonly a restart)
        // await waitForHealthy();

        // after the server becomes ready, wait for a second + random 1-4s timeout
        // (avoid DDoSing yourself) and try connecting again
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 + Math.random() * 3000)
        );
      },
    });

    return createClient({
      url: `${import.meta.env.VITE_APP_API!}/station/graphql`,
      fetchOptions: () => ({
        headers: {
          Authorization: jwt ? `Bearer ${jwt}` : "",
          "X-Certificate": cert || "",
          "X-Signature": signature || "",
        },
      }),
      exchanges: [
        errorExchange({
          onResult(result) {
            // If the network is back on or Heroku Maintenance Mode turned OFF, remove the Maintenance Mode
            if (result.data) {
              setMaintenanceMode(false);
            }
          },
          onError(error: any, operation) {
            console.log(error);
            // If network is down or Heroku Maintenance Mode turned ON, show the Maintenance Mode
            if (error.networkError == "TypeError: Failed to fetch") {
              setMaintenanceMode(true);
            }
            if (error.message.includes("Authentication error")) {
              hardReset();
              toast.error("Session Expired. Please login again.", {
                id: "session-expired",
              });
            }
          },
        }),
        fetchExchange,
        cacheExchange,
        mapExchange({
          onError(error, operation) {
            if (error?.message) {
              if (error.message == "[GraphQL] Invalid player session") {
                // If the player session is invalid, logout the user and redirect to login page
                hardReset();
              }
              console.info("Error message:", error.message);
              captureException(error.message.toString());
              toast.error(
                error.message?.toString()?.replace("[GraphQL]", "").trim(),
                {
                  id: "error",
                }
              );
            }
          },
        }),
        authExchange(async (utils) => ({
          addAuthToOperation(operation) {
            if (!jwt) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${jwt}`,
              "X-Certificate": cert || "",
              "X-Signature": signature || "",
            });
          },
          willAuthError() {
            var _isJwtStale: boolean = true;
            if (jwt) {
              _isJwtStale = isJwtStale(jwt);
              if (_isJwtStale === true) {
                return true;
              } else {
                return false;
              }
            }
            return true;
          },
          didAuthError(error) {
            if (
              error?.toString()?.includes("Unauthorized") ||
              error?.toString()?.includes("Invalid") ||
              error?.toString()?.includes("Authentication error")
            ) {
              return true;
            } else {
              console.error("error", error);
              return false;
            }
          },
          async refreshAuth() {
            try {
              const res = await auth.refresh();
              localStorage.setItem("jwt", res.token);
              localStorage.setItem("refreshToken", res.refreshToken);
            } catch {
              hardReset();
            }
          },
        })),
        subscriptionExchange({
          forwardSubscription(operation) {
            return {
              subscribe: (sink) => {
                const dispose = wsClient.subscribe(
                  operation as SubscribePayload,
                  sink
                );
                return {
                  unsubscribe: dispose,
                };
              },
            };
          },
        }),
      ],
    });
  }, [jwt, cert, signature]);
  return <GQLProvider value={client}>{children}</GQLProvider>;
};

export function isJwtStale(jwt: string): boolean {
  const payload = JSON.parse(atob(jwt.split(".")[1]));
  const expiration = payload.exp * 1000;
  return Date.now() >= expiration;
}
