import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import { useStationAuth } from "../../contexts/StationAuthContext";
import Iconify from "../Iconify";
import TermsAndConditions from "../Terms&Conditions";
import SweepstakesRules from "../SweepstakesRules";
import useResponsive from "../../hooks/useResponsive";
import PetView from "./PetView";
import StationDetails from "../home/StationDetails";
import {
  useCanClaimPetQuery,
  useGetUserPetsQuery,
} from "../../generated/graphql";
// ----------------------------------------------------------------------

export default function Sidebar() {
  const { playerId } = useStationAuth();

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showSweepstakesRules, setShowSweepstakesRules] = useState(false);
  const [rightMobileOpen, setRightMobileOpen] = useState(false);

  const isInGame = useLocation().pathname.includes("/game");
  const location = useLocation();
  const isHome = location.pathname === "/";
  const isLg = useResponsive("up", "lg");

  const [{ data: userPetsData }] = useGetUserPetsQuery({
    pause: !playerId,
    variables: { playerId: playerId! },
  });
  const userPets = userPetsData?.getUserPets;

  const [{ data: canClaimPetData }] = useCanClaimPetQuery({
    variables: { playerId: playerId! },
    pause: !playerId,
  });
  const canClaimPet = canClaimPetData?.canClaimPet;
  const showClaimPet = canClaimPet && userPets?.length === 0;

  const handleRightDrawerToggle = () => {
    setRightMobileOpen(!rightMobileOpen);
  };

  const SidebarContent = (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {playerId ? <PetView /> : <NonLoggedInView />}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* Sweepstakes Rules */}
        <Button
          variant="outlined"
          className="slanted-button"
          onClick={() => {
            setShowSweepstakesRules(true);
          }}
          sx={{
            borderRadius: "50px",
            border: "1px solid #FFF",
            mb: 0.5,
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Sweepstakes Rules
          </Typography>
        </Button>
        {/* Terms & Conditions */}
        <Button
          variant="outlined"
          className="slanted-button"
          onClick={() => {
            setShowTermsAndConditions(true);
          }}
          sx={{
            borderRadius: "50px",
            border: "1px solid #FFF",
            mb: 0.5,
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Terms & Conditions
          </Typography>
        </Button>

        {/* Station Details */}
        <StationDetails />
      </Box>
    </>
  );

  const RightMobileDrawer = (
    <Drawer
      variant="temporary"
      anchor="right"
      open={rightMobileOpen}
      onClose={handleRightDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        display: { xs: "block", lg: "none" },
        "& .MuiDrawer-paper": {
          overflow: "visible",
          boxSizing: "border-box",
          width: 300,
          background:
            "linear-gradient(172deg, rgba(86,138,254,1) 34%, rgba(255,55,128,1) 74%)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          p: 2,
        }}
      >
        {SidebarContent}
      </Box>
    </Drawer>
  );

  return (
    <>
      {/* Add right menu button to top bar */}
      {!isLg && isHome && (
        <IconButton
          color="inherit"
          aria-label="open right drawer"
          edge="end"
          onClick={handleRightDrawerToggle}
          sx={{
            position: "absolute",
            top: 13,
            right: 24,
          }}
        >
          <Iconify icon="ph:egg-crack" fontSize="32px" />
        </IconButton>
      )}

      {showClaimPet ? (
        <>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1200,
              "@media (max-width: 1200px)": {
                display: "none",
              },
            }}
          />
        </>
      ) : null}

      {/* Desktop Sidebar */}
      <Box
        sx={{
          display: { xs: "none", lg: "flex" },
          flexDirection: "column",
          justifyContent: "space-between",
          background: isInGame
            ? "linear-gradient(to bottom right, rgb(22 28 36), rgb(127 75 55 / 95%))"
            : "linear-gradient(172deg, rgba(86,138,254,1) 34%, rgba(255,55,128,1) 74%)",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
          width: "300px",
          maxWidth: "280px",
          zIndex: showClaimPet ? 1201 : 99,
          "@media (max-width: 1200px)": {
            display: "none",
          },
        }}
      >
        {SidebarContent}
      </Box>

      {/* Mobile Right Drawer */}
      {RightMobileDrawer}

      <TermsAndConditions
        showTermsAndConditions={showTermsAndConditions}
        setShowTermsAndConditions={setShowTermsAndConditions}
      />

      <SweepstakesRules
        showSweepstakesRules={showSweepstakesRules}
        setShowSweepstakesRules={setShowSweepstakesRules}
      />
    </>
  );
}

const NonLoggedInView = () => {
  const { setShowLoginForm } = useStationAuth();
  return (
    <Box
      onClick={() => {
        if (window.__TAURI__) setShowLoginForm(true);
        else setShowLoginForm(true);
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <img
          src="/images/sidebar/card.gif"
          alt="card"
          width="225px"
          style={{
            cursor: "pointer",
          }}
        />
      </Box>
      {window.__TAURI__ ? (
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            mt: 4,
          }}
        >
          Tap your card
          <br />
          to login!
        </Typography>
      ) : (
        <Typography
          onClick={() => setShowLoginForm(true)}
          sx={{
            fontSize: "20px",
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            mt: 4,
          }}
        >
          Login to play!
        </Typography>
      )}
    </Box>
  );
};
